'use client';

import {MotionConfig, type Transition} from 'motion/react';

const defaultTransition: Transition = {
  ease: [0.25, 1, 0.5, 1]
};

type Props = {
  children: React.ReactNode;
};

const MotionProvider = ({children}: Props) => {
  return <MotionConfig transition={defaultTransition}>{children}</MotionConfig>;
};

export default MotionProvider;
