import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"variable\":\"--font-jakarta\"}],\"variableName\":\"jakarta\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/HighlightProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MotionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles.css");
