'use client';

import {startTransition, useEffect} from 'react';
import {H, HighlightInit} from '@highlight-run/next/client';
import {useUser} from 'src/utils/auth/userAtoms';

const HighlightProvider = () => {
  const {user} = useUser();

  useEffect(() => {
    if (user) startTransition(() => H.identify(user._id, {name: user.firstName}));
  }, [user]);

  return (
    <HighlightInit
      excludedHostnames={['localhost']}
      projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
      serviceName="panelista-frontend"
      tracingOrigins
      networkRecording={{
        enabled: true,
        recordHeadersAndBody: false
      }}
    />
  );
};

export default HighlightProvider;
